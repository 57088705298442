<!--

      This view displays the list of recent orders. It is reachable from within
      the profile tab.

 -->

<template>
  <Page title='Rezepte suchen' :scrollevents="true">
    <ion-searchbar
      placeholder="Zutat, Rezept"
      class="search search-standalone"
      ref="searchbar"
      @ionChange="this.recipeSearch($event)"
      @ionClear="this.resetSearch()"
    ></ion-searchbar>

    <div class="tag-bar" v-if="(searchExclusions && searchExclusions.length > 0 && showSearchFilters) || dontExcludeFromSearch.length">
      <div class="tag-bar-header">
        <div class="tag-bar-text" v-if="!dontExcludeFromSearch.length">{{ searchExclusions.length }} Zutat{{ searchExclusions.length > 1 ? 'en' : '' }} aus der Suche ausgeschlossen</div>
        <div class="tag-bar-text btn-link" v-if="dontExcludeFromSearch.length" @click="resetExclusions()">Ausgeschlossene Zutaten wiederherstellen</div>
      </div>
      <div class="tag-bar-chips">
        <div
          class="exclude-tag"
          v-for="exclusion in this.searchExclusions"
          :key="exclusion.name"
          @click="this.removeSearchExclusion(exclusion.name)"
        >
          {{ exclusion.name_german ? exclusion.name_german : exclusion.name }}
          <Icon name="closeOutline" class="tag-remove-icon" />
        </div>
      </div>
    </div>

    <ion-skeleton-text animated class="skeleton-card" v-if="this.showSkeletonCards"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-card" v-if="this.showSkeletonCards"></ion-skeleton-text>
    <ion-skeleton-text animated class="skeleton-card" v-if="this.showSkeletonCards"></ion-skeleton-text>

    <div class="infinite-recipe-scroll">
      <RecipeCard
        v-for="r in this.visibleData"
        :recipe="r"
        :key="r.id"
        :showAvailability="true"
        />
      <ion-infinite-scroll
        @ionInfinite="this.loadNextPage($event)"
        threshold="1000px"
        :key="this.dontExcludeFromSearch.length"
        v-if="!showSorryNoResults && lastResultsObj && lastResultsObj.next"
      >
        <ion-infinite-scroll-content
          class="infinite-scrolling-loading"
          loading-spinner="bubbles">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </div>

    <NewCard v-if="showSorryNoResults" icon="sadOutline">
      Sorry! Hab leider nichts gefunden...
    </NewCard>
      
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import RecipeCard from '@/components/cards/RecipeCard'
import * as Sentry from '@sentry/browser'
import NewCard from '@/components/cards/NewCard.vue'

import {
  IonSearchbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSkeletonText
} from '@ionic/vue'

export default {
  name: 'search',
  components: {
    Page,
    RecipeCard,
    NewCard,
    IonSearchbar,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSkeletonText
  },
  data () {
    return {
      lastResultsObj: null,
      visibleData: [],
      searchTerm: '',
      showSearchFilters: true,
      controller: new AbortController(),
      dontExcludeFromSearch: []
    }
  },
  computed: {
    showSkeletonCards () {
      return !this.visibleData
    },
    searchExclusions () {
      if (this.$store.state.profile) {
        const activeExclusions = []
        this.$store.state.profile.ingredient_category_exclusions.forEach(i => {
          if (!this.dontExcludeFromSearch.includes(i.name)) {
            i.type = 'ingredient_category_exclusion'
            activeExclusions.push(i)
          }
        })
        this.$store.state.profile.shopping_ingredient_exclusions.forEach(i => {
          if (!this.dontExcludeFromSearch.includes(i.name)) {
            i.type = 'shopping_ingredient_exclusion'
            activeExclusions.push(i)
          }
        })
        return activeExclusions
      }
    },
    showSorryNoResults () {
      if (this.lastResultsObj && this.visibleData && this.visibleData.length === 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    resetExclusions () {
      this.dontExcludeFromSearch = []
      const artificialSearchEvent = {
        detail: {
          value: this.searchTerm
        }
      }
      this.recipeSearch(artificialSearchEvent)
    },
    resetSearch () {
      this.controller.abort()
      this.controller = new AbortController()
      this.searchTerm = ''
      this.visibleData = []
      this.lastResultsObj = null
    },
    recipeSearch (event) {
      this.resetSearch()
      if (event.detail.value.length > 0) {
        let searchUrl = this.$store.state.api.plan.weekly + this.$store.state.newplan.id
        if (this.$store.state.replaceme) {
          searchUrl += '/replace/' + this.$store.state.replaceme.id + '/search/'
        } else {
          searchUrl += '/add' + '/search/'
        }
        searchUrl += '?search=' + event.detail.value.toLowerCase()

        // Exclude selected ingredient category exclusions from the search
        // These are the items, which the user doens't want to eat
        const ingredientCategoryExclusions = this.searchExclusions.filter(e => e.type === 'ingredient_category_exclusion')
        if (ingredientCategoryExclusions.length > 0) {
          searchUrl += '&ingredient_category_ids__exclude='
          searchUrl += ingredientCategoryExclusions.map(i => i.id).join(',')
        }

        const shoppingIngredientExclusions = this.searchExclusions.filter(e => e.type === 'shopping_ingredient_exclusion')
        if (shoppingIngredientExclusions.length > 0) {
          searchUrl += '&shopping_ingredient_ids__exclude='
          searchUrl += shoppingIngredientExclusions.map(i => i.id).join(',')
        }

        const details = {
          url: searchUrl,
          id: '',
          signal: this.controller.signal,
          method: 'GET'
        }
        this.$store.dispatch('apiSend', details).then(result => {
          if (result && result.hasOwnProperty('results')) {
            this.lastResultsObj = result
            const recipeIds = result.results.map(recipe => recipe.id)
            this.$store.dispatch('getRecipesAvailability', recipeIds)
            this.visibleData = this.visibleData.concat(result.results)

            // Record recipe impressions
            const impressions = recipeIds.map(recipeId => (
              { recipe: recipeId, flag: this.$store.state.recipeImpressionFlags.SEEN }
            ))
            this.$store.dispatch('recordRecipeImpression', impressions)
          }
        })
      }
      this.searchTerm = event.detail.value
    },
    removeSearchExclusion (name) {
      this.dontExcludeFromSearch.push(name)
      const artificialSearchEvent = {
        detail: {
          value: this.searchTerm
        }
      }
      this.recipeSearch(artificialSearchEvent)
    },
    manageNewResults (results) {
      this.lastResultsObj = results
      this.visibleData = this.visibleData.concat(results.results)
    },
    loadNextPage (event) {
      if (this.lastResultsObj && this.lastResultsObj.next) {
        const lastResultsObj = this.lastResultsObj
        Sentry.configureScope(function (scope) {
          scope.setExtra('this.lastResultsObj', JSON.stringify(lastResultsObj))
        })
        const details = {
          url: this.lastResultsObj.next
        }
        this.$store.dispatch('apiSend', details).then(nextpage => {
          if (nextpage && nextpage.count) {
            event.target.disabled = false
            this.manageNewResults(nextpage)
            event.target.complete()
            if (!nextpage.next) {
              event.target.disabled = true
            }
          }
        })
      }
    }
  },
  ionViewDidEnter () {
    this.$refs.searchbar.$el.setFocus()
  }
}
</script>

<style lang="scss">
@import './../../theme/main.scss';

.tag-bar {
  text-align: left;
  margin: 0 20px;
}

.tag-bar-text {
  @extend .tertiary-text-gray;
  padding-left: 6px;
  padding-bottom: 3px;
  margin-top: -10px;
}

.tag-bar-chips {
  display: flex;
  flex-wrap: wrap;
}

.exclude-tag {
  background: white;
  border: solid 1px $col-font-green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $border-radius;
  padding: 4px 10px;
  margin: 2px;
  line-height: 1;
}

.tag-remove-icon {
  height: 16px;
  width: 16px;
  margin-left: 5px;
  margin-right: -4px;
  --stroke: #{$col-font-green};
  --stroke-width: 45px;
}

.tag-bar-header {
  display: flex;
  justify-content: space-between;
}
</style>
